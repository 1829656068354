import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const In_Depth_Sales = () => {
  return (
    <Layout>
      <Seo
        title="Amazon Sales"
        description="Detailed Amazon Sales per product and per marketplaces items"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">In Depth Sales Reports</Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-sales02.png"
                    alt="Amazon sales"
                    placeholder="tracedSVG"
                    layout="constrained"
                    //aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      See the sales for each item in each marketplace live.
                    </li>
                    <li>
                      Daily, Weekly, Monthly, and yearly sales charts for each
                      ASIN, each market, and all markets combined.
                    </li>
                    <li>See items sales percentage of your total sales.</li>
                    <li>See each marketplace sales and overall percentage.</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default In_Depth_Sales
